import {Flex, Tag, Tooltip} from 'antd';
import {ColumnsType} from 'antd/es/table';
import Highlighter from 'react-highlight-words';

import {classNames, formatDateTime, getSlug, showConfirmModal} from '~utils';
import {CommonObject, MediaContent, Pagination, User} from '~models';
import {DeleteIcon, EditIcon, PlayIcon, PauseIcon, EyeSquareIcon, ReplayIcon} from '~assets';
import {AppDispatch} from '~store';
import {
  deleteMediaContentThunk,
  editMediaContentStatusThunk,
  replayMediaContentThunk,
} from '~reducers';
import {navigate} from '~routes';

interface GetColumns {
  user?: User;
  pagination: Pagination;
  searchTerm?: string;
  mediaContentsLength: number;
  dispatch: AppDispatch;
  setSearchParams: (values: CommonObject<string>) => void;
  getMediaContents: () => void;
}

export const getColumns = ({
  user,
  pagination,
  searchTerm,
  mediaContentsLength,
  dispatch,
  setSearchParams,
  getMediaContents,
}: GetColumns): ColumnsType<MediaContent> => {
  const handlePlay = (mediaContent: MediaContent) => {
    showConfirmModal({
      title: 'Phát nội dung',
      content: 'Bạn chắc chắn muốn phát nội dung này?',
      onOk: () => {
        dispatch(
          editMediaContentStatusThunk({
            payload: {
              id: mediaContent.id,
              playing: true,
            },
            callback: getMediaContents,
          }),
        );
      },
    });
  };

  const handleReplay = (mediaContent: MediaContent) => {
    showConfirmModal({
      title: 'Phát lại nội dung',
      content: 'Bạn chắc chắn muốn phát lại nội dung này?',
      onOk: () => {
        dispatch(
          replayMediaContentThunk({
            id: mediaContent.id,
            callback: getMediaContents,
          }),
        );
      },
    });
  };

  const handlePause = (mediaContent: MediaContent) => {
    showConfirmModal({
      title: 'Ngừng phát nội dung',
      content: 'Bạn có chắc muốn ngừng phát nội dung này?',
      onOk: () => {
        dispatch(
          editMediaContentStatusThunk({
            payload: {
              id: mediaContent.id,
              playing: false,
            },
            callback: getMediaContents,
          }),
        );
      },
    });
  };

  const editMediaContent = (mediaContent: MediaContent) => {
    navigate(`/media-content/add/${mediaContent.id}`);
  };

  const handleDeleteMediaContent = (mediaContent: MediaContent) => {
    dispatch(
      deleteMediaContentThunk({
        id: mediaContent.id,
        callback: () => {
          if (pagination.current_page > 1 && mediaContentsLength === 1) {
            setSearchParams({current_page: 1});
          } else getMediaContents();
        },
      }),
    );
  };

  const deleteMediaContent = (mediaContent: MediaContent) => {
    showConfirmModal({
      title: 'Xoá nội dung',
      content: 'Xác nhận xoá nội dung khỏi danh sách này',
      onOk: () => handleDeleteMediaContent(mediaContent),
    });
  };

  const renderActions = (_: unknown, mediaContent: MediaContent) => {
    const isCreatedByOtherUser = user?.id !== mediaContent.created_by.id;

    const actions = [
      {
        title: mediaContent.playing ? 'Ngừng phát' : 'Phát',
        Icon: mediaContent.playing ? PauseIcon : PlayIcon,
        fill: mediaContent.playing ? '#C92127' : '#00B578',
        action: mediaContent.playing ? handlePause : handlePlay,
      },
      {
        title: 'Phát lại',
        Icon: ReplayIcon,
        action: handleReplay,
      },
      {
        title: 'Chỉnh sửa',
        Icon: EditIcon,
        fill: '#2560E5',
        action: editMediaContent,
        hide: isCreatedByOtherUser,
      },
      {
        title: 'Xoá',
        Icon: DeleteIcon,
        fill: '#C92127',
        action: deleteMediaContent,
        hide: mediaContent.playing || isCreatedByOtherUser,
      },
      {
        title: 'Xem',
        Icon: EyeSquareIcon,
        action: editMediaContent,
        hide: !isCreatedByOtherUser,
      },
    ];

    return (
      <Flex className="icons" gap={8}>
        {actions.map(({title, Icon, fill, action, hide}) => (
          <Tooltip key={title} title={title} placement="bottomRight">
            <Icon
              className={classNames('icon', {
                'icon--hide': hide,
              })}
              fill={fill}
              onClick={() => action(mediaContent)}
            />
          </Tooltip>
        ))}
      </Flex>
    );
  };

  const renderMediaContentName = (name: string) => (
    <Highlighter searchWords={[`${searchTerm}`]} textToHighlight={name} sanitize={getSlug} />
  );

  const renderStatus = (playing: boolean) => {
    return playing ? <Tag color="success">Đang phát</Tag> : <Tag color="error">Ngừng phát</Tag>;
  };

  const renderPlayingDevices = (_: unknown, mediaContent: MediaContent) => {
    return `${mediaContent.correct_plan_devices_num}/${mediaContent.total_devices_num}`;
  };

  const columns: ColumnsType<MediaContent> = [
    {
      title: 'STT',
      width: 80,
      render: (_, __, index) => Number(pagination.from) + index,
    },
    {
      title: 'Tên nội dung',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: renderMediaContentName,
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: 400,
    },
    {
      title: 'Số lượng thiết bị phát',
      width: 180,
      render: renderPlayingDevices,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 120,
      render: createdAt => formatDateTime(createdAt),
    },
    {
      title: 'Người tạo',
      dataIndex: 'created_by',
      key: 'created_by',
      width: 150,
      render: createdBy => createdBy?.name,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'playing',
      key: 'playing',
      width: 120,
      render: renderStatus,
    },
    {
      title: 'Thao tác',
      width: 150,
      render: renderActions,
    },
  ];

  return columns;
};
