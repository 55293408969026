import {useMemo} from 'react';
import {Flex} from 'antd';

import {PageHeader, Table} from '~components';
import {useGetData} from '~hooks';
import {getDevicesThunk} from '~reducers';
import {useAppDispatch} from '~store';
import {Device, DeviceFilterParams} from '~models';

import {getColumns} from './utils';
import {DeviceFilter, DeviceOnlineStatistic} from './components';
import './styles.scss';

const DevicePage = () => {
  const {
    isLoading,
    data: devices,
    getData: getDevices,
    searchParams,
    pagination,
    setSearchParams,
  } = useGetData<Device, DeviceFilterParams>(getDevicesThunk);

  const dispatch = useAppDispatch();

  const columns = useMemo(() => {
    return getColumns({
      pagination,
      searchTerm: searchParams.search,
      devicesLength: devices.length,
      dispatch,
      setSearchParams,
      getDevices,
    });
  }, [devices.length, pagination, searchParams.search, setSearchParams, dispatch, getDevices]);

  return (
    <div className="device">
      <PageHeader title="DANH SÁCH THIẾT BỊ" />

      <Flex className="device__info" align="stretch" gap={16}>
        <DeviceOnlineStatistic />
        <DeviceFilter searchParams={searchParams} setSearchParams={setSearchParams} />
      </Flex>

      <Table
        className="device__table"
        emptyText="Danh sách trống"
        loading={isLoading}
        columns={columns}
        dataSource={devices}
        pagination={{
          from: pagination.from,
          to: pagination.to,
          total: pagination.total,
          current: pagination.current_page,
          pageSize: pagination.per_page,
          onChange: current_page => setSearchParams({current_page}),
          onShowSizeChange: (_, per_page) => setSearchParams({per_page, current_page: 1}),
        }}
      />
    </div>
  );
};

export default DevicePage;
