import {memo} from 'react';
import {Flex, Form} from 'antd';

import {Input, Text, TextArea, UploadFile} from '~components';

import {AddMediaContentForm, attachmentRules, nameRules} from '../../utils';
import './styles.scss';

interface Props {
  isPlaying: boolean;
  isOtherOwner: boolean;
}

const MediaContentInfo = ({isPlaying, isOtherOwner}: Props) => {
  const formInstance = Form.useFormInstance<AddMediaContentForm>();
  const attachment = Form.useWatch<File>('attachment', formInstance);

  const {setFieldValue, resetFields} = formInstance;

  return (
    <div className="fields">
      <Text className="fields__title">Thông tin nội dung</Text>
      <div className="fields__content">
        <Form.Item name="name" rules={nameRules}>
          <Input label="Tên nội dung" placeholder="Tên nội dung" required disabled={isOtherOwner} />
        </Form.Item>
        <Flex gap={16}>
          <Form.Item name="description">
            <TextArea label="Mô tả nội dung" placeholder="Nhập mô tả" disabled={isOtherOwner} />
          </Form.Item>
          <Form.Item name="attachment" rules={attachmentRules}>
            <UploadFile
              label="Video"
              file={attachment}
              disabled={isPlaying || isOtherOwner}
              required
              onChange={file => setFieldValue('attachment', file)}
              onRemove={() => resetFields(['attachment'])}
            />
          </Form.Item>
        </Flex>
      </div>
    </div>
  );
};

export default memo(MediaContentInfo);
