import {ChangeEvent} from 'react';
import {Flex} from 'antd';

import {Input, Select} from '~components';
import {statusOptions} from '~variables';
import {DeviceFilterParams} from '~models';
import {useDebounceFunction} from '~hooks';

import './styles.scss';

interface Props {
  searchParams: DeviceFilterParams;
  setSearchParams: (values: DeviceFilterParams) => void;
}

const DeviceFilter = ({searchParams, setSearchParams}: Props) => {
  const handleSearchChange = useDebounceFunction<ChangeEvent<HTMLInputElement>>(event => {
    setSearchParams({search: event.target.value});
  });

  return (
    <Flex className="device-filter" gap={24}>
      <Input
        label="Tên cửa hàng"
        placeholder="Nhập tên"
        horizontal
        allowClear
        defaultValue={searchParams.search}
        onChange={handleSearchChange}
      />
      <Select
        label="Trạng thái"
        placeholder="---Chọn trạng thái---"
        horizontal
        allowClear
        value={searchParams.status}
        options={statusOptions}
        onChange={status => setSearchParams({status})}
      />
    </Flex>
  );
};

export default DeviceFilter;
