import {ReactNode, memo} from 'react';
import {Flex} from 'antd';

import {BackIcon} from '~assets';
import {navigate} from '~routes';

import Text from '../../base/Text';
import './styles.scss';

interface Props {
  title: string;
  backUrl?: string;
  actions?: ReactNode;
}

const PageHeader = ({backUrl, title, actions}: Props) => {
  return (
    <div className="page-header">
      {backUrl && (
        <span className="page-header__back" onClick={() => navigate(backUrl)}>
          <BackIcon />
        </span>
      )}
      <Text className="page-header__title">{title}</Text>
      {actions && (
        <Flex className="page-header__actions" gap={8}>
          {actions}
        </Flex>
      )}
    </div>
  );
};

export default memo(PageHeader);
