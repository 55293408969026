import {Form} from 'antd';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {AddMediaContentIcon} from '~assets';
import {useAppDispatch} from '~store';
import {navigate} from '~routes';
import {addMediaContentThunk, editMediaContentThunk} from '~reducers';
import {Button, PageHeader} from '~components';
import {getMediaContentByIdAPI} from '~services';
import {MediaContent} from '~models';
import {usePermission} from '~hooks';

import {MediaContentInfo, SelectDevices} from '../../components';
import {AddMediaContentForm, initialValues} from '../../utils';
import './styles.scss';

const AddMediaContentPage = () => {
  const [mediaContent, setMediaContent] = useState<MediaContent>();

  const dispatch = useAppDispatch();
  const {mediaContentId} = useParams();
  const {user} = usePermission();
  const [form] = Form.useForm<AddMediaContentForm>();

  const {submit, setFieldsValue} = form;
  const isPlaying = !!mediaContent?.playing;
  const isOtherOwner = !!(mediaContent && mediaContent.created_by.id !== user?.id);

  const goToMediaContentPage = () => {
    navigate('/media-content');
  };

  const handleSubmit = (values: AddMediaContentForm) => {
    if (mediaContentId) {
      dispatch(
        editMediaContentThunk({
          payload: {
            id: mediaContentId,
            ...values,
          },
          callback: goToMediaContentPage,
        }),
      );
    } else {
      dispatch(
        addMediaContentThunk({
          payload: values,
          callback: goToMediaContentPage,
        }),
      );
    }
  };

  const getTitle = () => {
    if (isOtherOwner) return 'THÔNG TIN NỘI DUNG';
    if (mediaContentId) return 'CHỈNH SỬA NỘI DUNG';
    return 'TẠO NỘI DUNG';
  };

  useEffect(() => {
    if (mediaContentId) {
      getMediaContentByIdAPI(mediaContentId).then(response => {
        setMediaContent(response.data.data);
      });
    }
  }, [mediaContentId]);

  useEffect(() => {
    if (mediaContent) {
      setFieldsValue({
        name: mediaContent.name,
        description: mediaContent.description,
        attachment: {
          id: mediaContent.attachment.id,
          name: mediaContent.attachment.title,
        },
        devices: mediaContent.devices,
      });
    }
  }, [mediaContent, setFieldsValue]);

  return (
    <div className="add-media-content">
      <PageHeader
        title={getTitle()}
        backUrl="/media-content"
        actions={
          !isOtherOwner && (
            <Button type="primary" size="large" icon={<AddMediaContentIcon />} onClick={submit}>
              {mediaContentId ? 'Lưu chỉnh sửa' : 'Tạo nội dung'}
            </Button>
          )
        }
      />

      <Form initialValues={initialValues} form={form} onFinish={handleSubmit}>
        <MediaContentInfo isPlaying={isPlaying} isOtherOwner={isOtherOwner} />
        <SelectDevices isPlaying={isPlaying} isOtherOwner={isOtherOwner} />
      </Form>
    </div>
  );
};

export default AddMediaContentPage;
