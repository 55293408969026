import {memo, useMemo} from 'react';
import {Form} from 'antd';

import {SearchIcon} from '~assets';
import {Device} from '~models';
import {getDevicesAPI} from '~services';
import {Input, Table, Text} from '~components';
import {GetDataSelectMode, useGetDataSelect} from '~hooks';

import {AddMediaContentForm, getDeviceColumns} from '../../utils';
import './styles.scss';

interface Props {
  isPlaying: boolean;
  isOtherOwner: boolean;
}

const SelectDevices = ({isPlaying, isOtherOwner}: Props) => {
  const {
    isLoading,
    data: devices,
    pagination,
    searchTerm,
    setPagination,
    handleSearchChange,
  } = useGetDataSelect<Device>(getDevicesAPI, GetDataSelectMode.PAGE);

  const formInstance = Form.useFormInstance<AddMediaContentForm>();
  const selectedDevices = Form.useWatch<Device[]>('devices') ?? [];

  const {setFieldValue} = formInstance;
  const selectedDeviceIds = selectedDevices.map(({id}) => id);

  const handleSelectRow = (record: Device, selected: boolean) => {
    const newSelectedDevices = selected
      ? selectedDevices.concat(record)
      : selectedDevices.filter(t => t.id !== record.id);
    setFieldValue('devices', newSelectedDevices);
  };

  const handleSelectAll = (selected: boolean, allRows: Device[]) => {
    const newSelectedDevices = selected ? allRows : [];
    setFieldValue('devices', newSelectedDevices);
  };

  const columns = useMemo(() => getDeviceColumns({searchTerm}), [searchTerm]);

  return (
    <div className="devices">
      <div className="devices__header">
        <Text className="devices__header-label">Thiết bị chiếu</Text>
        <Input
          className="devices__header-input"
          placeholder="Nhập tên cửa hàng"
          allowClear
          prefix={<SearchIcon />}
          onChange={event => handleSearchChange(event.target.value)}
        />
      </div>
      <Table
        className="devices__table"
        emptyText="Danh sách trống"
        showScroll={false}
        loading={isLoading}
        columns={columns}
        dataSource={devices}
        rowSelection={{
          checkStrictly: false,
          selectedRowKeys: selectedDeviceIds,
          onSelect: handleSelectRow,
          onSelectAll: handleSelectAll,
          getCheckboxProps: () => ({
            disabled: isPlaying || isOtherOwner,
          }),
        }}
        pagination={{
          from: pagination.from,
          to: pagination.to,
          total: pagination.total,
          current: pagination.current_page,
          pageSize: pagination.per_page,
          onChange: current_page => setPagination({...pagination, current_page}),
          onShowSizeChange: (_, per_page) => {
            setPagination({...pagination, per_page, current_page: 1});
          },
        }}
      />
    </div>
  );
};

const MemorizedSelectDevices = (props: Props) => (
  <Form.Item name="devices">
    <SelectDevices {...props} />
  </Form.Item>
);

export default memo(MemorizedSelectDevices);
